<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入书名">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入书名即可，例如：骆驼祥子"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="选择模型">
          <el-radio-group v-model="ruleForm.extParam.modelCode">
              <el-radio :label="1">标准版</el-radio>
              <el-radio :label="2">
                专业版
                <el-tooltip class="item" effect="light" content="篇幅更长,研究领域更广,内容更精准" placement="top-start">
                  <i class="el-icon-info" style="color: #ffaa00;"></i>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
        </el-form-item>
      </el-form>

    </div>

    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import CommonApi from "@/api/common"
  import { orderMixin } from '@/utils/orderMixin'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  export default {
    components: {NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '',
          creationDocId: 6,
          creationDocCode: 'REVIEW',
          channelCode: null,
          extParam: {
            version: 1,
            modelCode: 1,
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入书名', trigger: 'blur' }
        //   ],
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        paramsData: '', // 储存跳页面的传值
			}
		},
		created() {},
		methods: {
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入书名',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)

        // _this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
</style>
